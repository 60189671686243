<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="title">Judul:</label>
              <validation-provider
                name="title"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.title"
                  placeholder="Judul Kompetisi"
                />
                <small
                  v-for="(validation, index) in validations.title"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="type">Tipe:</label>
              <validation-provider name="type" rules="required">
                <div class="demo-inline-spacing" style="margin-top: 8px">
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="typeOptions"
                      class="custom-control-input"
                      type="radio"
                      id="tipe1"
                      value="photo"
                      v-model="formPayload.type"
                    />
                    <label class="custom-control-label" for="tipe1">
                      Photo
                    </label>
                  </div>
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="typeOptions"
                      class="custom-control-input"
                      type="radio"
                      id="tipe2"
                      value="video"
                      v-model="formPayload.type"
                    />
                    <label class="custom-control-label" for="tipe2">
                      Video
                    </label>
                  </div>
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="typeOptions"
                      class="custom-control-input"
                      type="radio"
                      id="tipe3"
                      value="article"
                      v-model="formPayload.type"
                    />
                    <label class="custom-control-label" for="tipe3">
                      Article
                    </label>
                  </div>
                </div>
                <small
                  v-for="(validation, index) in validations.type"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="description">Description:</label>

              <validation-provider
                name="description"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <textarea
                  v-model="formPayload.description"
                  id="description"
                  class="form-control"
                  cols="30"
                  rows="10"
                  maxlength="2500"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                />
                <small
                  v-for="(validation, index) in validations.description"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="type">Status:</label>
              <validation-provider name="type" rules="required">
                <div class="demo-inline-spacing" style="margin-top: 8px">
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="statusOptions"
                      class="custom-control-input"
                      type="radio"
                      id="status1"
                      value="publish"
                      v-model="formPayload.status"
                    />
                    <label class="custom-control-label" for="status1">
                      Publish
                    </label>
                  </div>
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="statusOptions"
                      class="custom-control-input"
                      type="radio"
                      id="status2"
                      value="unpublish"
                      v-model="formPayload.status"
                    />
                    <label class="custom-control-label" for="status2">
                      Unpublish
                    </label>
                  </div>
                </div>
                <small
                  v-for="(validation, index) in validations.status"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="">Image:</label><br />
              <p class="image__hint"><i>Only Jpg, Image</i></p>
              <validation-provider
                name="title"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <b-form-file
                  class="mb-2"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/jpeg, image/png"
                  @change="changeFile($event)"
                />
                <small>Current file:</small><br />
                <img
                  v-if="photo_url !== null"
                  :src="photo_url"
                  class="media_file"
                />
                <small
                  v-for="(validation, index) in validations.image"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="theme">Tema:</label>
              <validation-provider
                name="title"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="theme"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.information.theme"
                  placeholder="Theme"
                />
                <small
                  v-for="(validation, index) in validations[
                    'information.theme'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group row">
              <b-col cols="12" lg="6">
                <label for="startDate">Start Date:</label>
                <flat-pickr
                  id="startDate"
                  v-model="formPayload.information.start_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' }"
                />
                <small
                  v-for="(validation, index) in validations[
                    'information.start_date'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </b-col>
              <b-col cols="12" lg="6">
                <label for="endDate">End Date:</label>
                <flat-pickr
                  id="endDate"
                  v-model="formPayload.information.end_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' }"
                />
                <small
                  v-for="(validation, index) in validations[
                    'information.end_date'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </b-col>
            </div>
            <div class="form-group">
              <label for="rules">Rules:</label>
              <validation-provider
                name="description"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <textarea
                  v-model="formPayload.information.rules"
                  id="description"
                  class="form-control"
                  cols="30"
                  rows="10"
                  maxlength="1500"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                />
                <small
                  v-for="(validation, index) in validations[
                    'information.rules'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="reward">Reward:</label>
              <quill-editor
                ref="editor"
                id="reward"
                @change="handleTextChange"
                v-model="formPayload.information.reward"
              />
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    quillEditor,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  props: ["maxlength"],

  data() {
    return {
      required,
      limit: 1500,
      exceededMax: false,
      isLoading: false,
      formPayload: {
        title: "",
        type: "",
        image: "",
        description: "",
        status: "",
        information: {
          start_date: "",
          end_date: "",
          theme: "",
          rules: "",
          reward: "",
        },
      },
      photo_url: null,
      validations: "",
      // Must be an array reference!
    };
  },

  mounted() {
    this.loadCompetition();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    handleTextChange() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength();
      if (len > this.limit) {
        quill.deleteText(this.limit, len);
      }
    },
    changeFile(event) {
      this.formPayload.image = event.target.files[0];
      this.showFile(event.target.files[0]);
    },
    showFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photo_url = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    loadCompetition() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/competitions/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.photo_url = this.formPayload.image;
        });
    },
    saveItem() {
      this.isLoading = true;
      const params = this.$route.params.uuid;
      const payload = new FormData();
      payload.append("title", this.formPayload.title);
      payload.append("type", this.formPayload.type);
      payload.append("description", this.formPayload.description);
      payload.append("status", this.formPayload.status);
      payload.append("image", this.formPayload.image);
      payload.append(
        "information[start_date]",
        this.formPayload.information.start_date
      );
      payload.append(
        "information[end_date]",
        this.formPayload.information.end_date
      );
      payload.append("information[theme]", this.formPayload.information.theme);
      payload.append("information[rules]", this.formPayload.information.rules);
      payload.append(
        "information[reward]",
        this.formPayload.information.reward
      );
      this.$http
        .post(`/api/v1/admin/competitions/${params}`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Competition Berhasil diedit!");
          this.$router.push({ name: "competition" });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
            errorNotification(this, "Judul", this.validations.title.join(""));
            errorNotification(this, "Tipe", this.validations.type.join(""));
            errorNotification(
              this,
              "Description",
              this.validations.description.join("")
            );
            errorNotification(this, "Status", this.validations.status.join(""));
            errorNotification(
              this,
              "Start Date",
              this.validations["information.start_date"].join("")
            );
            errorNotification(
              this,
              "End Date",
              this.validations["information.end_date"].join("")
            );
            errorNotification(
              this,
              "Rules",
              this.validations["information.rules"].join("")
            );
            errorNotification(
              this,
              "Theme",
              this.validations["information.theme"].join("")
            );
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.media_file {
  width: 400px;
  height: 300px;
  object-fit: cover;
}
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
</style>
